  
  // export const SERVICE_URL = "/app";
  export const IS_Login = localStorage.getItem("admin_isLogin") === "true" ? true : false;
  export const IS_AUTH_GUARD_ACTIVE = true;
  export const ADMIN_SERVICE_URL ="http://micropays.in/admin/api/v1"//
  export const AGENT_SERVICE_URL ="http://micropays.in/agent/api/v1"//
  export const DEFAULT_TOKEN = 'anshPay eyJhbGciOiJSUzI1NiIsImtpZCI6ImtleS1yczI1NiIsInR5cCI6IkpXVCJ9';
  export const DEFAULT_AGENTTOKEN = 'agentanshPay eyJhbGciOiJSUzI1NiIsImtpZCI6ImtleS1yczI1NiIsInR5cCI6IkpXVCJ9';
  export const IMAGE_URL ="http://micropays.in/uploads/"//"http://aroramoneytransfer.info/uploads/"
  // For detailed information: https://github.com/nfl/react-helmet#reference-guide
  export const REACT_HELMET_PROPS = {
    defaultTitle: "Micro Pays",
    titleTemplate: "Micro Pays",
  };
  
  
  export const DEFAULT_PATHS = {
    APP: "/",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    USER_WELCOME: "/dashboards/default",
    NOTFOUND: "/page-not-found",
    UNAUTHORIZED: "/unauthorized",
    INVALID_ACCESS: "/invalid-access",
  };
  
  